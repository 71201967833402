import React, {useEffect, useState} from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundSection from "../components/BackgroundSection"
import MainContent from "../components/atoms/MainContent"
import Heading from "../components/atoms/Heading";
import Paragraph from "../components/atoms/Paragraph"
import {css} from "@emotion/core";
import ScreenShotImage from "../components/atoms/ScreenShotImage"
import {isPC} from "../util/user-agent";

const ContainerStyles = css`
  padding: 80px;
  text-align: left;
  @media(max-width: 767px) {
    padding: 30px;
  }
`

const PortfolioPage = () => {
  const [ua, setUa] = useState("")
  useEffect(() => {
    setUa(navigator.userAgent.toLowerCase())
  }, [])
  const screenshotSize = isPC(ua) ? 600 : 240
  return (
    <Layout>
      <BackgroundSection>
        <SEO title="Portfolio Page"/>
        <MainContent>
          <div css={ContainerStyles}>
            <Heading level={2}>ポートフォリオ</Heading>
            <Paragraph>個人開発のポートフォリオです</Paragraph>
            <div style={{marginTop: 50, width: screenshotSize}}>
              <ScreenShotImage filename="myportfolio_screenshot.png" alt="myportfolio_screenshot"/>
            </div>
            <div style={{marginTop: 50}}>
              <Heading level={3}>mabui labo(ポートフォリオサイト)</Heading>
              <Paragraph>当サイトです。Gatsby.jsとTypeScriptを使用して作成しています。</Paragraph>
            </div>

            <div style={{marginTop: 100, width: screenshotSize}}>
              <a href="https://mabui.org/ipo-monitor-service-intro/" target="_blank" rel="noopener noreferrer">
                <ScreenShotImage filename="ipomonitor_screenshot.png" alt="ipomonitor_screenshot"/>
              </a>
            </div>
            <div style={{marginTop: 50}}>
              <Heading level={3}>IPO monitor (未公開)</Heading>
              <Paragraph>
                作成中の、チャートモニタリングサービスです。
                React.js, Next.jsでのフロントエンド、Pythonでの日々の情報更新・
                画像生成バッチ、チャート表示apiを作成しました。
              </Paragraph>
            </div>

            <div style={{marginTop: 100, width: screenshotSize}}>
              <a href="https://note.mu/mabui/n/n30b78f487505" target="_blank" rel="noopener noreferrer">
                <ScreenShotImage filename="bitflyerbot_screenshot.png" alt="bitflyerbot_screenshot"/>
              </a>
            </div>
            <div style={{marginTop: 50}}>
              <Heading level={3}>bitFlyerリアルタイム自動売買bot</Heading>
              <Paragraph>仮想通貨の自動売買ボットスクリプトをPythonで作成し、noteで販売しました。</Paragraph>
            </div>
          </div>
        </MainContent>
      </BackgroundSection>
    </Layout>
    )
}

export default PortfolioPage
